html {
    height: 100%;
}
body {
    height: 100%;
}
body.use-affix {
    height: auto;
    min-height: 800px;
    background-color: #f5f5f5;

    & .wrap {
        min-height: 800px;
        background-color: #ffffff;
    }
}
